<template>
  <div>
    <!-- 进行中 -->
    <!-- 电脑端 -->
    <div class="roll-room" v-show="item.rollClass == type &&isShow">
      <div class="room-top t-l">{{ item.name }}</div>
      <!-- 已结束与进行中 -->
      <!-- <div class=" d-flex">
        <div class="state">{{ state }}</div>
      </div>-->
      <!-- 加入条件 -->
      <!-- <div class="info color-white">{{item.roll_info}}</div> -->
      <!-- 开奖时间 -->
      <!-- <div class="color-white">{{ item.endTime }}</div> -->
      <div class="roll-main">
        <div class="body justify d-flex">
          <!-- <div class="pers f-13">参加人数：{{ item.perLimit }}</div>
          <div class="f-13 color-white">
            价值：
            <img src="@/assets/images/shopping/bean.png" alt />
            {{ item.priceAll }}
          </div>-->
        </div>

        <div class="roll-bottom">
          <div class="gfts d-flex">
            <div class="gft" v-for="(t, index) in arr3" :key="index">
              <div class="bgimg">
                <img :src="t" alt style="width: 60px" />
              </div>
            </div>
            <div class="body f-13">
              <img src="@/assets/roll/gifs.png" alt />
              {{ item.countAll }}
            </div>
          </div>
        </div>
      </div>

      <div class="rollBtn color-white" @click="active">加入</div>
    </div>
    <!-- 手机端 -->
    <div class="roll-rooms" v-show="item.rollClass == type &&isShow">
      <!-- 已结束与进行中 -->
      <!-- <div class=" d-flex">
        <div class="state">{{ state }}</div>
      </div>-->
      <!-- 加入条件 -->
      <div class="namebox d-flex">
        <div class="room-top t-l">{{ item.name }}</div>
        <div class="info color-white">{{ item.roll_info }}</div>
      </div>
      <!-- 开奖时间 -->
      <div class="namebox d-flex">
        <div class="opentime">开奖时间{{ item.startTime }}</div>
        <div class="rollBtn" @click="active">进入房间</div>
      </div>
      <div class="roll-main">
        <div class="body justify d-flex">
          <div class="f-13">奖品数量：{{ item.countAll }}</div>
          <div class="pers f-13">参加人数：{{ item.perLimit }}</div>
          <div class="f-13 color-white">
            总价值：
            <img src="@/assets/images/shopping/bean.png" alt width="15px" height="15px" />
            {{ item.priceAll }}
          </div>
        </div>

        <div class="roll-bottom">
          <div class="gfts d-flex">
            <div class="gft" v-for="(t, index) in arr3" :key="index">
              <div class="bgimg">
                <img :src="t" alt style="width: 60px" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getToken } from "../../../api/cookie";
export default {
  name: "room",
  data() {
    return {
      imgs: this.item.imgs,
      arrImgs: [],
      arr3: [],
      isShow: true,
      token: getToken(),
      state: ""
    };
  },
  props: {
    item: Object,
    type: String
  },
  created() {
    //把字符串根据逗号分成数组
    this.arrImgs = this.imgs.split(",");
    this.arr3 = this.arrImgs.slice(0, 4);
    var endDate = this.item.endTime;
    endDate = endDate.replace(/-/g, "/");
    var DateM = new Date(endDate).getTime(); //得到毫秒数
    var time = new Date().getTime();
    if (time < DateM) {
      this.isShow = true;
      this.state = "进行中";
    } else {
      this.isShow = false;
      this.state = "已结束";
    }
  },
  mounted() {
    this.token = getToken();
  },
  methods: {
    active() {
      if (!this.token) {
        this.$bus.$emit("unlogin", true);
      } else {
        this.$router.push("/active/" + this.item.id);
      }
    }
  }
};
</script>

<style scoped lang="scss">
a {
  text-decoration: none !important;
}

.roll-room {
  width: 320px;
  background: url("~@/assets/roll/rollbg.png") no-repeat;
  background-size: 100% 100%;
  padding: 10px;
  box-sizing: border-box;
  margin: 30px;
  height: 120px;
  position: relative;
  @media (max-width: 550px) {
    display: none;
  }
  .room-top {
    width: 80%;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    color: #fff;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    // 没置超出部分显示省略号
    overflow: hidden;
    text-overflow: ellipsis;
    .state {
      font-size: 15px;
      color: #fee303;
    }
  }
  .roll-main {
    width: 100%;
    margin: 10px 0;
    position: relative;
    .gft {
      width: 70px;
      height: 50px;
      background: url("~@/assets/roll/gifts.png") no-repeat;
      background-size: 100% 100%;
    }
    .body {
      color: #c9e0ff;
      flex-wrap: wrap;
      width: 90%;
      margin: auto;
      line-height: 2;
    }
  }
  .rollBtn {
    width: 100px;
    height: 100px;
    text-align: center;
    line-height: 100px;
    background: url("~@/assets/roll/jion.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 10px;
    right: -20px;
  }
}
.roll-rooms {
  display: none;
}
@media (max-width: 550px) {
  .roll-rooms {
    width: 100%;
    height: 210px;
    background: url("~@/assets/roll/phone/rollbgs.png") no-repeat;
    background-size: 100% 100%;
    padding: 8px;
    box-sizing: border-box;
    margin: 15px auto;
    position: relative;
    display: block;
    .namebox {
      padding: 0 15px 0 0;
      justify-content: space-between;
      align-items: center;
      .room-top {
        font-size: 20px;
        color: yellow;
        font-weight: bold;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        // 没置超出部分显示省略号
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .info {
        font-weight: bold;
      }
    }
    .opentime {
      color: yellow;
      margin-top: 14px;
      margin-right: 10px;
    }
    .roll-main {
      width: 100%;
      margin: 10px 0;
      position: relative;
      .gft {
        width: 68px;
        height: 78px;
        background: url("~@/assets/roll/gifts.png") no-repeat;
        background-size: 100% 100%;
        .bgimg {
          margin-top: 15px;
        }
      }
      .body {
        color: #c9e0ff;
        flex-wrap: wrap;
        width: 90%;
        margin: auto;
        line-height: 2;
      }
    }
    .rollBtn {
      width: 82px;
      height: 26px;
      line-height: 26px;
      margin-top: 5px;
      background-color: #fff000;
      border-radius: 3px;
      text-align: center;
    }
  }
}
</style>