<template>
  <div>
    <div class="roll">
      <!-- 电脑端 -->
      <div class="roll-nav d-flex j-a">
        <div class="all" @click="rollBtn('going')">
          <!-- <img v-if="btnActive === 'going'" src="@/assets/roll/alls.png" alt />
          <img v-else src="@/assets/roll/all.png" alt />-->
          <div :class="btnActive == 'going' ? 'gold' : ''">所有Roll房</div>
        </div>
        <!-- <div class="over" @click="rollBtn('over')">
          <div :class="btnActive=='over'?'gold':''">主播</div>
        </div> -->
        <div class="my" @click="rollBtn('my')">
          <div :class="btnActive == 'my' ? 'gold' : ''">我的Roll房</div>
        </div>
      </div>
      <!-- <input type="text" v-model="this.name"><button>确定</button> -->
      <div class="room-list">
        <div class="rooms" v-if="show == 1">
          <div class="room" v-for="(item, index) in roomList" :key="index">
            <roomItem :item="item" :type="type"></roomItem>
          </div>
        </div>
        <div class="rooms" v-else-if="show == 2">
          <div class="room" v-for="(item, index) in roomLists" :key="index">
            <roomItems :item="item" :type="type"></roomItems>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import roomItem from "./common/room.vue";
import roomItems from "./common/userRoom.vue";
import { getRollList, getUserInfo, getuserRoll } from "../../api/index";
export default {
  name: "rollHome",
  data() {
    return {
      btnActive: "going",
      roomList: [],
      roomLists: [],
      isShow: true,
      type: "0",
      userid: "",
      show: 1,
    };
  },

  mounted() {
    getUserInfo().then((res) => {
      // console.log(res);
      this.userid = res.data.user.id;
    });
    getRollList().then((res) => {
      // console.log(res);
      this.roomList = res.data.data;
      // console.log(res.data.data);
    });
    getuserRoll().then((res) => {
      this.roomLists = res.data.rows;
      console.log(this.roomLists);
    });
  },
  components: {
    roomItem,
    roomItems,
  },
  methods: {
    going() {},
    rollBtn(state) {
      this.btnActive = state;
      if (state == "going") {
        this.show = 1;
        this.type = "0" || "1";
      } else if (state == "my") {
        this.show = 2;
        this.type = "2";
        console.log(this.show);
      }
      // console.log(this.type);
      // else if (state == "over") {
      //   this.type = "1";
      // }
    },
  },
};
</script>

<style scoped lang="scss">
.gold {
  color: #fff000;
}
.all,
.over,
.my {
  font-size: 20px;
  font-weight: none;
  font-style: italic;
}
.mobile {
  display: none;
}
.roll {
  padding-top: 25px;
  box-sizing: border-box;
  width: 1131px;
  height: 460px;
  margin: 50px auto;
  background: url("~@/assets/images/home/mainallbg.png") no-repeat;
  background-size: 100% 100%;
  .roll-nav {
    width: 320px;
    margin: auto;
    color: #fff;
  }
  .rooms {
    width: 1200px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    height: 300px;
    overflow: auto;
    margin-top: 10px;
  }
}
@media (max-width: 550px) {
  .roll {
    padding-top: 0px;
    box-sizing: border-box;
    width: 100%;
    height: 460px;
    margin: 0px auto;
    background: none;
    .roll-nav {
      width: 320px;
      margin: auto;
      color: #fff;
    }
    .room-list {
      height: 1000px;
    }
    .rooms {
      width: 100%;
      margin: auto;
      justify-content: center;
      height: auto;
      overflow: auto;
      margin-top: 10px;
    }
  }
}
</style>
